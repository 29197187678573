import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { small, smallExtended } from 'modules/app/styles/Button.styles';

export const wrapper = css`
  max-width: 2400px;
  margin: 0 auto;
  padding: ${spacing(20)};
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.large} {
    padding: ${spacing(20)} ${spacing(40)};
  }
`;

export const divider = css`
  border: none;
  border-bottom: 1px solid hsl(var(--color-grayscale-2));
  margin-top: auto;
  padding-top: var(--spacing-med);
  margin-right: ${spacing(-20)};
`;

export const navbar = css`
  background-color: hsl(var(--color-white-11));
  position: relative;

  .nav__wrapper--mobile {
    box-sizing: border-box;
    position: absolute;
    top: 100%; // Navbar height
    left: 0;
    height: 0;
    width: 100%;
    background-color: hsl(var(--color-white-11));
    overflow: hidden;
    z-index: 4;

    @media ${breakpoints.large} {
      display: none;
    }

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 9vh var(--spacing-xlrg) calc(var(--unit) * 5.5)
        var(--spacing-xlrg);
    }

    .nav__links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: var(--spacing-med);
    }

    .nav__actions {
      .nav__social {
        padding-top: calc(var(--unit) * 3.5);

        p {
          color: hsl(var(--color-grayscale-4));
          text-transform: uppercase;
          margin-right: 49px;
        }
      }

      .nav__social-links {
        a {
          color: hsl(var(--color-grayscale-6));
          font-size: var(--font-size-small);
          line-height: var(--line-height-small);
        }
      }
    }

    .link,
    .link--active {
      opacity: 1;
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-xxLarge);
      line-height: var(--line-height-xxLarge);
      transition: opacity 0.4s ease-in-out;
    }

    .link {
      color: hsl(var(--color-grayscale-4));

      &:hover {
        color: hsl(var(--color-text-1));

        ::after {
          left: calc(100% + var(--spacing-lrg));
          bottom: calc(var(--line-height-xxLarge) / 2);
          background-color: hsl(var(--color-grayscale-4));
          width: 3rem;
        }
      }
    }

    .link--active {
      ::after {
        left: calc(100% + var(--spacing-lrg));
        bottom: calc(var(--line-height-xxLarge) / 2);
        background-color: hsl(var(--color-grayscale-4));
        width: 3rem;
      }
    }
  }

  .nav__menu-btn {
    @media ${breakpoints.large} {
      display: none;
    }
  }
`;

export const navbarNegative = css`
  ${navbar};
  background-color: hsl(var(--color-text-1));
  color: hsl(var(--color-white-11));

  .nav__wrapper--mobile {
    background-color: hsl(var(--color-text-1));
    color: hsl(var(--color-white-11));

    .link {
      &:hover {
        color: hsl(var(--color-white-11));

        ::after {
          background-color: hsl(var(--color-white-11));
        }
      }
    }
  }
`;

export const navbarGray = css`
  ${navbar};
  background-color: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-white-11));

  .nav__wrapper--mobile {
    background-color: hsl(var(--color-grayscale-9));
    color: hsl(var(--color-white-11));

    .link {
      &:hover {
        color: hsl(var(--color-white-11));

        ::after {
          background-color: hsl(var(--color-white-11));
        }
      }
    }
  }
`;

export const navbarLight = css`
  ${navbar};
  background-color: hsl(var(--color-borderUltraLight-10));

  .nav__wrapper--mobile {
    background-color: hsl(var(--color-borderUltraLight-10));

    .link {
      &:hover {
        ::after {
          background-color: hsl(var(--color-borderUltraLight-10));
        }
      }
    }
  }
`;

export const navBarMobileBtn = css`
  @media ${breakpoints.smallMax} {
    display: block;
  }
`;

export const navbarPrimary = css`
  margin-right: var(--spacing-lrg);

  .nav__links {
    display: none;
  }

  @media ${breakpoints.large} {
    flex-grow: 1;
    justify-content: space-between;
    margin-right: unset;

    .nav__links {
      display: flex;
    }

    display: flex;
    align-items: center;
    column-gap: calc(var(--unit) * 9.5);
  }
`;

export const navbarUtils = css`
  display: flex;
  align-items: center;
  column-gap: calc(var(--unit) * 3.25);
`;

export const logo = css`
  flex-grow: 1;

  @media ${breakpoints.large} {
    max-width: 220px;
  }

  svg {
    height: calc(var(--unit) * 4);
    width: auto;
  }

  @media ${breakpoints.medium} {
    svg {
      height: ${spacing(24)};
      width: 100px;
    }
  }
`;

export const navbarLink = css`
  display: none;

  @media ${breakpoints.large} {
    display: flex;
    align-items: center;
    column-gap: var(--spacing-xxxlrg);
  }

  .link,
  .link--active {
    font-weight: normal;
    font-size: var(--font-size-small);
    color: hsl(var(--color-text-1));
    position: relative;
    transition: all 0.2s ease-in;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: hsl(var(--color-text-1));
      transition: all 0.3s ease-out;
    }
  }

  .link:hover,
  .link--active {
    &::after {
      width: 100%;
    }
  }
`;

export const navbarLinkNegative = css`
  ${navbarLink};

  .link,
  .link--active {
    color: hsl(var(--color-white-11));

    &::after {
      background-color: hsl(var(--color-white-11));
    }
  }
`;

export const button = css`
  border-radius: 100px !important;
  cursor: pointer;

  a {
    color: inherit;
  }

  @media ${breakpoints.large} {
    ${smallExtended}
  }
`;

export const buttonAlt = css`
  ${button};

  &:hover,
  &:active {
    background-color: hsl(var(--color-white-10));
  }

  @media ${breakpoints.large} {
    ${small}
  }
`;

export const buttonTextNegative = css`
  ${buttonAlt};
  color: hsl(var(--color-white-11));

  &:hover,
  &:active {
    background-color: hsl(var(--color-white-3));
  }
`;

export const buttonNegative = css`
  ${button};
  background-color: hsl(var(--color-white-11));
  color: hsl(var(--color-text-1));

  &:hover,
  &:active {
    background-color: hsl(var(--color-white-9));
  }
`;

export const navbarBtn = css`
  position: relative;
  margin-left: ${spacing(12)};

  @media ${breakpoints.large} {
    display: none;
  }
`;

export const navbarIcon = css`
  width: ${spacing(16)};
  height: ${spacing(1.5)};
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background: hsl(var(--color-grayscale-9));
  transition: all 0.2s ease-in-out;

  ::before {
    content: '';
    position: absolute;
    width: ${spacing(8)};
    height: ${spacing(1.5)};
    top: ${spacing(-4)};
    right: 0;
    background: hsl(var(--color-grayscale-9));
    transition: all 0.3s ease-in-out;
    transform-origin: center;
  }

  ::after {
    content: '';
    position: absolute;
    width: ${spacing(8)};
    height: ${spacing(1.5)};
    top: ${spacing(4)};
    right: ${spacing(8)};
    background: hsl(var(--color-grayscale-9));
    transition: all 0.3s ease-in-out;
    transform-origin: center;
  }
`;

export const navbarIconActive = css`
  ${navbarIcon};
  height: 0;
  width: ${spacing(12)};

  ::before {
    width: ${spacing(12)};
    top: 0;
    right: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  ::after {
    width: ${spacing(12)};
    top: 0;
    right: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
`;

export const navbarIconDark = css`
  ${navbarIcon};
  background: hsl(var(--color-white-11));

  ::before {
    background: hsl(var(--color-white-11));
  }

  ::after {
    background: hsl(var(--color-white-11));
  }
`;

export const navbarIconDarkActive = css`
  ${navbarIconActive};

  ::before {
    background: hsl(var(--color-white-11));
  }

  ::after {
    background: hsl(var(--color-white-11));
  }
`;

export const search = css`
  min-height: 32px;

  input {
    min-height: 30px;
    padding: 0 ${spacing(16)};
    max-width: 146px;
    ${font.weight.bold}
  }

  @media ${breakpoints.large} {
    min-height: 40px;

    input {
      min-height: 38px;
    }
  }
`;

export const searchWrapper = css`
  @media ${breakpoints.smallMax} {
    display: none;
  }
`;
